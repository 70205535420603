import { Component, EventEmitter, HostListener, Input, Output, booleanAttribute } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MaterialModule } from "../../../material.module";
import { PreventDoubleClickDirective } from "../../directives/prevent-double-click.directive";

@Component({
    standalone: true,
    selector: 'form-dialog',
    imports: [MaterialModule, PreventDoubleClickDirective],
    templateUrl: 'form-dialog.component.html'
})
export class FormDialogComponent {

    @Input() dialogTitle: string;

    @Input() dialogSubtitle: string;

    @Input({ transform: booleanAttribute }) saveDisabled: boolean;

    @Input() saveButtonLabel: string = 'Confirm';

    @Input({ transform: booleanAttribute }) customActions: boolean;

    @Input() loading: boolean;

    @Input({ transform: booleanAttribute }) componentsManager: boolean;

    @Input({ transform: booleanAttribute }) hideCloseButton: boolean;

    @Output() saveAction = new EventEmitter<void>();

    constructor(private dialogRef: MatDialogRef<FormDialogComponent>) { }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.dialogRef.close();
            event.preventDefault();
        } else if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            if (!this.saveDisabled) {
                this.saveAction.emit();
            }
            event.preventDefault();
        }
    }

}